import React, { ReactElement } from "react"
import { Header } from "../components/Header"
import { TermsCondition } from "../components/terms-condition/TermsCondition"
import { Footer } from '../components/Footer';
import { Meta } from '../components/Meta';

interface Props { }

function Terms(_props: Props): ReactElement {
  return (
    <>
      <Meta title="Điều khoản sử dụng | cokhach.vn" />
      <Header />
      <TermsCondition />
      <Footer />
    </>
  )
}

export default Terms
