import React from "react"
import { PageTitle } from "../parts/Title"

export function TermsCondition() {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <PageTitle>Điều khoản sử dụng</PageTitle>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <p className="leading-relaxed text-base">
                cokhach.vn là tổ hợp giải pháp Marketing toàn diện dành cho
                ngành Bất động sản, với mục tiêu tăng lượt chuyển đổi khách hàng
                tiềm năng và tiết kiệm tối đa chi phí quảng cáo. cokhach.vn được
                vận hành và phát triển bởi Công ty TNHH Game Changer. Ngoài việc
                hỗ trợ các công cụ công nghệ trực tuyến, chúng tôi không tham
                gia vào quá trình trực tiếp sử dụng của cá nhân và doanh nghiệp.
                Các Khách hàng đồng ý sử dụng cokhach.vn sẽ mặc nhiên đồng ý với
                Điều khoản sử dụng này. Chúng tôi sẵn sàng giải thích cho Khách
                hàng về các điều khoản. Mọi thắc mắc vui lòng liên hệ theo email
                info@cokhach.vn. cokhach.vn bảo toàn quyền chỉnh sửa nội dung
                các điều khoản sử dụng này tại bất kì thời điểm nào. Chúng tôi
                sẽ thông báo tới quý Khách hàng khi có cập nhật trong Điều khoản
                sử dụng.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                1. Các định nghĩa
              </h2>
              <p className="leading-relaxed text-base">
                cokhach.vn: là tổ hợp giải pháp, nền tảng phần mềm Marketing
                toàn diện dành cho ngành Bất động sản, với mục tiêu tăng lượt
                chuyển đổi khách hàng tiềm năng và tiết kiệm tối đa chi phí
                quảng cáo được vận hành tại địa chỉ cokhach.vn.
                <br />
                Khách hàng: Là các Pháp nhân hoặc Cá nhân có nhu cầu sử dụng các
                công cụ của phần mềm cokhach.vn.
                <br />
                Tài khoản cokhach.vn của Khách hàng: Là tài khoản sử dụng hệ
                thống cokhach.vn của Khách hàng được truy cập thông qua đường
                dẫn https://cokhach.vn/dashboard/. Đây là không gian làm việc
                hiệu quả của Khách hàng và các cá nhân được Khách hàng cấp quyền
                sử dụng.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                2. Tài khoản cokhach.vn của Khách hàng
              </h2>
              <p className="leading-relaxed text-base">
                Tài khoản cokhach.vn của Khách hàng được tạo trực tiếp thông qua
                tài khoản Facebook, hoặc Google, hoặc được tạo từ email của
                khách hàng. Khách hàng có trách nhiệm phải tự bảo mật mật khẩu
                và tài khoản Facebook hoặc Google của mình cũng như chịu trách
                nhiệm hoàn toàn về các hoạt động liên quan đến tài khoản
                cokhach.vn của Khách hàng.
                <br />
                Khách hàng có trách nhiệm thông báo ngay cho cokhach.vn khi phát
                hiện nguy cơ, sự cố hoặc lỗi bảo mật. cokhach.vn không thể và sẽ
                không chịu trách nhiệm cho bất cứ sự mất mát, hư hỏng hay trách
                nhiệm nào khác nếu Khách hàng không tuân thủ điều lệ này hay do
                sự truy cập trái phép vào tài khoản của Khách hàng.
                <br />
                Trong trường hợp có tranh chấp giữa hai hoặc nhiều bên về quyền
                sở hữu tài khoản, Khách hàng đồng ý rằng cokhach.vn sẽ là trọng
                tài duy nhất của tranh chấp đó và quyết định của cokhach.vn (có
                thể bao gồm việc chấm dứt hoặc đình chỉ tài khoản tranh chấp) là
                quyết định cuối cùng và ràng buộc tất cả các bên.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                3. Bảo vệ quyền riêng tư
              </h2>
              <p className="leading-relaxed text-base">
                cokhach.vn bảo mật toàn bộ thông tin của Khách hàng và những nội
                dung các tài khoản sử dụng của Khách hàng trao đổi, lưu trữ trên
                nền tảng cokhach.vn. Chúng tôi sẽ thực hiện và duy trì tất cả
                các biện pháp bảo vệ nội bộ cần thiết và trong khả năng của
                chúng tôi; mang tính hành chính, vật lý và kỹ thuật để bảo vệ
                cho tính bảo mật và toàn vẹn đối với dữ liệu Khách hàng.
                cokhach.vn cam kết:
                <ul className="list-disc pl-5">
                  <li>
                    Không can thiệp, sửa đổi các tài liệu, dữ liệu được tạo ra
                    hoặc thu được bởi Khách hàng.
                  </li>
                  <li>
                    Không tiết lộ dữ liệu Khách hàng trừ trường hợp phải tuân
                    theo quy định của pháp luật hoặc được Khách hàng cho phép.
                  </li>
                  <li>
                    Không truy cập vào dữ liệu của Khách hàng trừ trường hợp
                    khắc phục lỗi kỹ thuật hoặc theo yêu cầu của Khách hàng.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                4. Dữ liệu của Khách hàng và trách nhiệm của các bên
              </h2>
              <p className="leading-relaxed text-base">
                Dữ liệu của Khách hàng là mọi thông tin mà Khách hàng gửi, tải
                lên, truyền hoặc bằng bất kể phương thức nào giúp hiển thị thông
                tin đó trên Tài khoản cokhach.vn của Khách hàng. Dữ liệu đó bao
                gồm: các văn bản, các tập tin, các hình ảnh, các video, các
                đường dẫn, các nội dung liên quan đến công việc và tất cả mọi
                thông tin được hiển thị trên tài khoản cokhach.vn của Khách
                hàng; Dữ liệu của Khách hàng bao gồm cả mọi dữ liệu, thông tin,
                đường dẫn, văn bản mà Khách hàng nhập vào cokhach.vn thông qua
                các nền tảng thứ 3 như Facebook, Google. Khách hàng có quyền sử
                dụng và kiểm soát việc truy cập dữ liệu được đăng tải trên tài
                khoản hệ thống cokhach.vn của Khách hàng. Khách hàng chịu mọi
                trách nhiệm cho tất cả các dữ liệu mà Khách hàng hoặc tài khoản
                sử dụng của Khách hàng gửi đến và hoặc gửi đi từ cokhach.vn.
                Khách hàng hiểu rằng cokhach.vn không sở hữu bất kì dữ liệu nào
                mà Khách hàng đăng tải lên cokhach.vn; trừ các dữ liệu được
                cokhach.vn chủ động cung cấp công khai cho Khách hàng. Việc
                quyết định đưa dữ liệu nào lên tài khoản cokhach.vn là toàn
                quyền của Khách hàng. Tuy nhiên, những nội dung sau sẽ KHÔNG
                ĐƯỢC PHÉP đăng tải trên cokhach.vn:
                <ul className="list-disc pl-5">
                  <li>
                    Làm tổn hại, làm phiền cho người khác hoặc gây ra thương tổn
                    đến con người và tài sản
                  </li>
                  <li>
                    Liên quan đến việc công bố các thông tin hoặc tài liệu lừa
                    đảo, gây mất uy tín danh dự, quấy rối hoặc mang tính khiêu
                    dâm.
                  </li>
                  <li>
                    Xâm phạm các quyền riêng tư hoặc kì thị chủng tộc, tôn giáo,
                    giới tính, người tàn tật.
                  </li>
                  <li>
                    Xâm phạm quyền sở hữu trí tuệ hoặc các quyền sở hữu khác.
                  </li>
                  <li>
                    Cản trở hoặc phá hỏng cokhach.vn (bao gồm nhưng không giới
                    hạn bởi việc truy cập trái phép cokhach.vn thông qua bất cứ
                    phương tiện máy móc, phần mềm).
                  </li>
                </ul>
                cokhach.vn có toàn quyền vô hiệu hóa quyền truy cập vào nội dung
                dữ liệu của Khách hàng nếu chúng tôi xác định dữ liệu được truy
                cập vi phạm Điều khoản này, hoặc chúng tôi nhận được đơn khiếu
                nại từ tài khoản sử dụng, hoặc thông báo vi phạm sở hữu trí tuệ
                và/hoặc thông báo nội dung có dấu hiệu vi phạm pháp luật của các
                cơ quan có thẩm quyền.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                5. Các nền tảng thứ ba thu thập dữ liệu người dùng
              </h2>
              <p className="leading-relaxed text-base">
                cokhach.vn có thể sử dụng các nền tảng Facebook Business Suite,
                Trình quản lý quảng cáo, Trình quản lý sự kiện, Google
                Analytics, Firebase... để hỗ trợ việc thu thập và phân tích hành
                vi người dùng. Các bên thứ ba này thu thập thông tin về cách
                người dùng truy cập vào cokhach.vn (thông qua trình duyệt nào,
                thiết bị nào, vị trí nào) và hành vi, hoạt động của người dùng
                trên hệ thống cokhach.vn dựa theo chính sách riêng của từng bên.
                cokhach.vn sử dụng thông tin thu thập được cho việc tìm hiểu,
                phân tích hành vi, nhu cầu khách hàng nhằm cải tiến sản phẩm,
                dịch vụ đang cung cấp đồng thời phát triển thêm các tính năng,
                sản phẩm, dịch vụ mới phục vụ cho khách hàng. Các nền tảng trên
                đều là các hệ thống lớn phổ biến trên thế giới được đảm bảo các
                tiêu chuẩn bảo mật thông tin cá nhân và dữ liệu người dùng.
                cokhach.vn không kiểm soát việc các bên thứ ba lưu trữ và sử
                dụng các dữ liệu thu thập theo điều khoản và chính sách họ đưa
                ra. Khách hàng có thể đọc các điều khoản và các chính sách được
                công bố bởi các bên thứ ba này trên trang web của họ để nắm rõ
                thông tin. Khách hàng sử dụng hệ thống của cokhach.vn cũng đồng
                nghĩa với việc tuân thủ các điều khoản dịch vụ của các bên thứ
                ba này.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                6. Tạm ngừng và chấm dứt sử dụng cokhach.vn{" "}
              </h2>
              <p className="leading-relaxed text-base">
                <h3>6.1. Thời hạn của thỏa thuận</h3>
                Thỏa thuận này bắt đầu kể từ ngày Khách hàng đồng ý và chấm dứt
                khi tất cả các tài khoản được cấp kèm theo thỏa thuận này hết
                hạn sử dụng. Đối với trường hợp Khách hàng dùng thử, nếu Khách
                hàng không chuyển sang hình thức thuê bao trước khi hết hạn dùng
                thử, thỏa thuận này sẽ được chấm dứt khi hết hạn dùng thử.
                <h3>6.2. Tạm ngừng</h3>
                cokhach.vn có quyền tạm ngừng việc sử dụng dịch vụ của Khách
                hàng trong các trường hợp sau:
                <ul className="list-disc pl-5">
                  <li>
                    Khách hàng không thực hiện nghĩa vụ thanh toán các khoản chi
                    phí sử dụng dịch vụ sau khi cokhach.vn đã gửi thông báo yêu
                    cầu thanh toán 15 ngày.
                  </li>
                  <li>
                    cokhach.vn cho rằng hệ thống cokhach.vn đang được Khách hàng
                    sử dụng để tham gia vào các hoạt động bất hợp pháp hoặc việc
                    sử dụng hệ thống cokhach.vn của Khách hàng gây nguy hại tới
                    cokhach.vn và cộng đồng.
                  </li>
                </ul>
                Thỏa thuận được coi như chấm dứt trong các trường hợp sau
                <ul className="list-disc pl-5">
                  <li>
                    cokhach.vn đơn phương chấm dứt thỏa thuận do Khách hàng
                    không thực hiện nghĩa vụ thanh toán cho cokhach.vn theo quy
                    định tại Điều 6 của thỏa thuận này.
                  </li>
                  <li>
                    cokhach.vn đơn phương chấm dứt thỏa thuận theo yêu cầu của
                    tòa án và cơ quan có thẩm quyền của nhà nước.
                  </li>
                  <li>
                    Khách hàng gửi thông báo yêu cầu chấm dứt thỏa thuận thuê
                    bao cho cokhach.vn bằng văn bản.
                  </li>
                  <li>
                    cokhach.vn không có nghĩa vụ hoàn trả bất kể chi phí nào mà
                    Khách hàng đã thanh toán trong trường hợp chấm dứt thỏa
                    thuận vì những lý do đã nêu trên. cokhach.vn chỉ bảo đảm duy
                    trì dữ liệu của Khách hàng trên hệ thống tối đa là 12 tháng
                    kể từ ngày chấm dứt thỏa thuận.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                7. Điều khoản bảo đảm và giới hạn trách nhiệm của cokhach.vn
              </h2>
              <p className="leading-relaxed text-base">
                <h3>7.1. Từ chối đảm bảo</h3>
                cokhach.vn không cam đoan, tuyên bố, hoặc bảo đảm rằng việc sử
                dụng phần mềm cokhach.vn của Khách hàng sẽ không bị gián đoạn
                hoặc không bị lỗi, hoặc phần mềm cokhach.vn sẽ đáp ứng yêu cầu
                Khách hàng hoặc tất cả các lỗi trên phần mềm và/hoặc tài liệu sẽ
                được sửa hoặc hệ thống tổng thể đảm bảo hoạt động của nền tảng
                cokhach.vn (bao gồm nhưng không giới hạn: mạng internet, các
                mạng truyền dẫn khác, mạng nội bộ và các thiết bị của Khách
                hàng). cokhach.vn không đảm bảo dưới bất kỳ hình thức nào, dù rõ
                ràng hay ngầm định về các điều kiện như sự thỏa mãn về chất
                lượng, phù hợp cho nhu cầu sử dụng đặc thù hoặc không xâm phạm
                các quyền của bên thứ ba. Nền tảng cokhach.vn được cung cấp dưới
                dạng “theo hiện trạng - as is” và “có sẵn - as available” cho
                Khách hàng sử dụng. Khách hàng sẽ chịu toàn bộ trách nhiệm trong
                việc xác định xem nền tảng cokhach.vn hoặc thông tin được tạo ra
                từ nền tảng cokhach.vn là đúng đắn và đáp ứng đầy đủ cho mục
                đích sử dụng của mình.
                <h3>7.2. Giới hạn về trách nhiệm pháp lý</h3>
                Trong bất cứ trường hợp nào cokhach.vn đều không chịu trách
                nhiệm về bất kỳ các thiệt hại nào trực tiếp, gián tiếp, ngẫu
                nhiên hoặc mang tính chất trừng phạt, bao gồm nhưng không giới
                hạn ở các thiệt hại do mất doanh thu, mất lợi nhuận, mất lợi thế
                kinh doanh, do ngừng việc hoặc mất mát dữ liệu do hậu quả của:
                (1) việc sử dụng hoặc không thể sử dụng nền tảng cokhach.vn; (2)
                bất kỳ các thay đổi nào được thực hiện đối với nền tảng
                cokhach.vn; (3) từ chối quyền truy cập hoặc biến đổi các dữ
                liệu; (4) xóa, sai hỏng, hoặc không lưu trữ dữ liệu có trên hoặc
                thông qua nền tảng cokhach.vn; (5) các tuyên bố hay hành vi của
                bất kỳ bên thứ ba nào đối với nền tảng cokhach.vn; và (6) bất kỳ
                vấn đề nào khác liên quan đến nền tảng cokhach.vn.
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                8. Bất khả kháng
              </h2>
              <p className="leading-relaxed text-base">
                Sự kiện bất khả kháng là sự kiện xảy ra mang tính khách quan và
                nằm ngoài tầm kiểm soát của các bên như: thiên tai, chiến tranh
                hoặc có nguy cơ xảy ra chiến tranh và các thảm họa tự nhiên khác
                chưa lường hết được; sự thay đổi chính sách hoặc ngăn cấm của cơ
                quan có thẩm quyền của Việt Nam; sự gián đoạn, chấm dứt dịch vụ
                do quyết định hoặc chính sách của bên thứ ba hoặc các đối tác
                cung cấp dịch vụ cho cokhach.vn; hoặc các hành vi tấn công mạng
                bất ngờ có chủ đích vào cokhach.vn.
              </p>
              <p className="leading-relaxed text-base">
                Việc một bên không hoàn thành nghĩa vụ của mình do sự kiện bất
                khả kháng sẽ không phải là cơ sở để bên kia chấm dứt hợp đồng và
                bồi thường thiệt hại. Tuy nhiên Bên bị ảnh hưởng bởi sự kiện bất
                khả kháng có nghĩa vụ phải:
                <ul className="list-disc pl-5">
                  <li>
                    Tiến hành các biện pháp ngăn ngừa hợp lý và các biện pháp
                    thay thế cần thiết để hạn chế tối đa ảnh hưởng do sự kiện
                    bất khả kháng gây ra.
                  </li>
                  <li>
                    Thông báo bằng văn bản cho bên kia về trường hợp được miễn
                    trách nhiệm và những hậu quả có thể xảy ra do sự kiện bất
                    khả kháng trong vòng 03 ngày ngay sau khi có cơ sở để xác
                    định việc xảy ra sự kiện bất khả kháng làm ảnh hưởng trực
                    tiếp đến khả năng thực hiện nghĩa vụ hợp đồng.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/1 flex">
            <div className="flex-grow pl-6">
              <h2 className="text-gray-900 text-lg title-font font-medium mb-2">
                9. Giải quyết tranh chấp
              </h2>
              <p className="leading-relaxed text-base">
                Tất cả các tranh chấp hoặc các bất đồng phát sinh giữa 02 bên sẽ
                được giải quyết trên tinh thần hợp tác để cùng nhau giải quyết.
                Trong trường hợp 02 bên thương lượng không thể đạt được kết quả
                thì sẽ đưa ra giải quyết theo trình tự tố tụng tại Tòa án có
                thẩm quyền. Phán quyết của Tòa án là chung thẩm, mang quyết định
                cuối cùng buộc các bên phải tuân thủ.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
